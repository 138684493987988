header {
	position: fixed;
	box-sizing: border-box;
	width: 100vw;
	padding: 17px 35px;
	z-index: 110;
	background-color: $c-black;
	transition: all 0.25s ease-out;
}

.header--transparent header {
	background-color: transparent;
}

header.opaque,
.header--transparent.nav-visible header {
	background-color: $c-black;
}

.touch .header--transparent header.menu--lang-visible {
	background-color: $c-black;
}

header > div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 2490px;
	margin: auto;
}

header .menu--wrapper {
	display: flex;
	position: relative;
}

header .menu--wrapper:last-child {
	padding-left: 20px;
}

.menu--lang {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: -0.4em;
	left: -1.25em;
	height: 1.15em;
	min-height: 2em;
	cursor: pointer;
}

header.menu--lang-visible .menu--lang {
	height: auto;
	z-index: 10;
}

.menu--lang li {
	opacity: 0;
	order: 2;
	text-align: center;
	transition: all 0.25s ease-out;
}

.menu--lang li{
	pointer-events: none;
}

.no-touch .menu--lang li:not(.selected),
.no-touch .menu--lang:hover li.selected {
	background: $c-black;
}

.menu--lang li.selected,
.no-touch .menu--lang:hover li,
header.menu--lang-visible .menu--lang li {
	opacity: 1;
	pointer-events: all;
}
/*
.no-touch header.menu--lang-visible .menu--lang li{
	@media $mq-tablet{
		background: $c-black;
	}
} */

.menu--lang li.selected {
	order: 1;
	pointer-events: none;
}

.menu--lang li a {
	display: block;
	padding: 10px 10px 9px;
	font-family: 'Lato', sans-serif;
	font-weight: 300;
}

.no-touch .menu--lang li:not(.selected):hover {
	background: $c-primary-1;
}

header li a {
	color: $c-white;
	text-decoration: none;
	@add-mixin fontResponsive 12px, 14px, 1;
}

header [data-is-menu-trigger] {
	position: relative;
	width: 28px;
	height: 14px;
	margin-left: 10px;
	border-top: 2px solid #fff;
	cursor: pointer;
}

header [data-is-menu-trigger]:before,
header [data-is-menu-trigger]:after {
	content: '';
	position: absolute;
	left: 0;
	width: 100%;
	height: 2px;
	background: #fff;
}

header [data-is-menu-trigger]:before {
	top: 50%;
	transform: translateY(-50%);
}

header [data-is-menu-trigger]:after {
	top: 100%;
}

header [data-is-menu-trigger],
header [data-is-menu-trigger]:before,
header [data-is-menu-trigger]:after {
	transition: all 0.25s ease-out;
}

.no-touch header [data-is-menu-trigger]:hover {
	border-color: $c-primary-1;
}

.no-touch header [data-is-menu-trigger]:hover:before,
.no-touch header [data-is-menu-trigger]:hover:after {
	background-color: $c-primary-1;
}

.nav-visible header [data-is-menu-trigger] {
	transform: rotateZ(-45deg) translateX(0px) translateY(7px);
}

.nav-visible header [data-is-menu-trigger]:before {
	opacity: 0;
}

.nav-visible header [data-is-menu-trigger]:after {
	transform: rotate(90deg) translateX(-14px) translateY(1px);
}

/* Esta cabecera duplicada nos sirve para que el contenido fluya a pesar de tener la otra cabecera con position:fixed */
.adjustment-header header {
	position: relative;
	visibility: hidden;
}

.header--transparent .adjustment-header {
	display: none;
}

.header--transparent.nav-visible .nav--wrapper .adjustment-header {
	display: initial;
}
