.collection-feature figcaption {
	position: relative;
	z-index: 2;
	/* overflow-y: hidden; */
}

.collection-feature > div {
	position: relative;
	z-index: 3;
}

.collection-feature:nth-child(odd) {
	padding-bottom: 0;
}

.collection-feature:nth-child(odd) figcaption {
	padding-top: calc(21px + (1500vw - 5400px) / 920);
}

.collection-feature:nth-child(odd) figcaption:after,
.collection-feature.collection-feature-video:after {
	content: '';
	position: absolute;
	top: 0;
	left: calc(50% - 100vw);
	right: calc(50% - 100vw);
	bottom: -3em;
	background-color: $c-primary-1;
	z-index: -1;
}

.collection-feature.collection-feature-video > div:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 3em;
	left: 75%;
	width: 33px;
	height: 33px;
	background: transparent url(../../media/img/icon-play.png) 0 0 no-repeat;
	pointer-events: none;
}

.collection-feature.collection-feature-video.playing > div:after {
	display: none;
}

.collection-feature.collection-feature-video figcaption:after {
	display: none;
}

.collection-feature.collection-feature-video:after {
	bottom: 0;
	background-color: #000;
}

.collection-feature.collection-feature-video figure > div {
	padding: 2rem 0;
}

.collection-feature:nth-child(odd) figcaption h2 {
	color: $c-white;
}

.collection-feature.collection-feature-video h2,
.collection-feature.collection-feature-video p {
	color: $c-white;
}

@media screen and (min-width: 1024px) {
	.figure-2-columns-wrapper .collection-feature {
		padding: 0 0 2em;
		margin-bottom: 2em;
	}

	.figure-2-columns-wrapper .collection-feature:nth-child(odd) {
		flex-direction: row-reverse;
	}

	.figure-2-columns-wrapper .collection-feature:nth-child(even) {
		flex-direction: row;
	}

	.collection-feature.collection-feature-video:after {
		background: #000;
	}

	.collection-feature:nth-child(odd) figcaption:after {
		display: none;
	}

	/* .collection-feature > div {
		width: percentage(576 / 1020);
		max-width: none;
	}

	.collection-feature figcaption {
		width: percentage(444 / 1020);
		max-width: none;
	} */

	.collection-feature figcaption,
	.collection-feature:nth-child(odd) figcaption {
		padding: 4em 0 0 0;
	}

	.collection-feature:nth-child(2) figcaption {
		padding-top: 0;
		margin-top: 0;
	}

	.collection-feature:before,
	.collection-feature:after {
		content: '';
		position: absolute;
		left: calc(50% - 100vw);
		right: calc(50% - 100vw);
	}

	.collection-feature:after {
		top: -2px;
		bottom: -2em;
		background-color: $c-primary-1;
		z-index: 1;
	}

	.collection-feature:before {
		background-color: $c-white;
		z-index: 2;
	}

	.collection-feature:nth-child(even):before {
		top: 2em;
		bottom: -2px;
	}

	.collection-feature:nth-child(2):before {
		top: -4px;
		bottom: -2px;
	}

	.collection-feature:nth-child(odd):before {
		top: -4px;
		height: 2em;
	}

	.collection-feature:nth-child(even):after {
		top: -2px;
		bottom: 0;
	}

	.collection-feature:nth-of-type(even) img {
		margin-left: 0;
		margin-right: auto;
	}

	.collection-feature:nth-of-type(odd) img {
		margin-left: auto;
		margin-right: 0;
	}

	.collection-feature.collection-feature-video {
		align-items: center;
		padding: calc(41px + (1500vw - 5400px) / 920) 0;
		/* margin-bottom: calc(41px + (1500vw - 5400px) / 920); */
		margin-bottom: 5em;
	}

	.collection-feature.collection-feature-video > div:after {
		top: 75%;
		left: 85%;
	}
}

@media $mq-desktop-xl {
	.collection-feature img {
		margin: 0;
	}

	.collection-feature figcaption {
		margin-top: 2em;
	}

	.collection-feature:nth-child(even):before {
		bottom: 5em;
	}

	.collection-feature:nth-child(even):after {
		top: auto;
		bottom: -3em;
		height: 8em;
	}

	.collection-feature:nth-child(odd):before {
		height: 0;
	}

	.collection-feature:nth-child(odd):after {
		bottom: 5em;
	}

	.collection-feature.collection-feature-video:after {
		bottom: 0;
	}

	.collection-feature:last-child:after {
		display: none;
	}

	.collection-feature figcaption,
	.collection-feature:nth-child(odd) figcaption {
		padding: 0;
	}
}

/* @media $mq-desktop-xxl {
	.figure-2-columns-wrapper .collection-feature {
		max-width: toVw(1488, 2560);
	}

	.collection-feature > div {
		width: percentage(785 / 1488);
	}
	.collection-feature figcaption {
		width: percentage(703 / 1488);
	}

	.collection-feature img {
		width: calc(447px + 90 * ((100vw - 1920px) / 640));
		max-width: 537px;
	}

	.collection-feature h2 {
		font-size: calc(34px + 8 * ((100vw - 1920px) / 640));
	}

	.collection-feature p {
		font-size: 22px;
	}
} */
