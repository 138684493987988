body {
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	font-size: 20px;
}

h1 {
	color: $c-white;
	font-family: 'Playfair Display', serif;
	font-weight: 700;
	@add-mixin fontResponsive 30px, 62px, 1;
	text-align: center;
	margin: 0 auto;
}

h2 {
	color: $c-primary-1;
	font-family: 'Playfair Display', serif;
	font-weight: 700;
	@add-mixin fontResponsive 34px, 34px, 1.3;
}

h3 {
	color: $c-black-light;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	margin: 0 auto;
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: responsive 11px 16px;
	font-range: $w-desktop-xl $w-desktop-xxl;
	line-height: 1.25;
}

h4 {
	color: $c-primary-1;
	border-bottom: 1px solid $c-primary-1;
	padding-bottom: 14px;
	margin: 20px 0;
	text-transform: uppercase;
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	@add-mixin fontResponsive 18px, 18px;
}

p {
	color: $c-black-light;
	margin-bottom: 8px;
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	font-size: 15px;
	line-height: 1.4;

	@media $mq-desktop {
		font-size: calc(16px + 7 * ((100vw - 1024px) / 1536));
	}

	&.textTitle,
	strong {
		font-family: 'Lato', sans-serif;
		font-weight: 700;
	}
}

p {
	@media screen and (min-width: 1961px) {
		line-height: 1.6;
	}
}

.page-intro p,
.page-intro strong {
	font-family: 'Playfair Display', serif;
	font-weight: 700;
	font-size: responsive 20px 26px;
	font-range: $w-mobile $w-desktop;

	@media screen and (min-width: 1961px) {
		font-size: 32px;
	}
}

.boldText {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
}

.textHighlighted {
	margin: 20px auto 80px;
	text-align: center;
	width: 92%;
	max-width: 790px;
	word-break: break-word;
	font-family: 'Playfair Display', serif;
	font-weight: 700;
	@add-mixin fontResponsive 22px, 24px, 1.4;

	p {
		font-family: 'Playfair Display', serif;
		font-weight: 700;
		@add-mixin fontResponsive 22px, 28px, 1.4;

		strong {
			color: $c-primary-1;
		}
	}
}

.titleSection {
	text-transform: uppercase;
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	@add-mixin fontResponsive 13px, 26px;
}

.blackColor {
	color: $c-black-light;
}
.colorWhite {
	color: $c-white;
}
.primaryColor {
	color: $c-primary-1;
}

a {
	text-decoration: none;
}
