.button,
button {
	position: relative;
	cursor: pointer;
	border: none;
	background-color: $c-primary-1;
	color: $c-white;
	display: inline-block;
	letter-spacing: 0.2em;
	line-height: 1.5;
	padding: 5px;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
	outline: none;
	z-index: 1;

	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 11px;

	@media $mq-tablet {
		margin: 8px 4px 0 4px;
		padding: 15px 10px;
	}

	&:hover {
		/*background-color: lighten($c-primary-1, 5%);*/
	}

	&.blackButton {
		background-color: $c-black-light;
		color: $c-white;
		&:hover {
			/*background-color: lighten($c-black-light, 5%);*/
		}
	}
}

.no-touch .button:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $c-primary-hover;
	z-index: -1;

	transform-origin: 0 50%;
	transform: scaleX(0);
	transition: transform 0.25s ease-out;
}

.no-touch .button:hover:after {
	transform: scaleX(1);
}

.downloadButton {
	cursor: pointer;
	background-color: $c-primary-1;
	padding: 18px 0;
	width: 100%;

	font-family: 'Lato', sans-serif;
	font-weight: 700;
	@add-mixin fontResponsive 13px, 13px;

	@media $mq-tablet {
		padding: 24px 0;
		max-width: 270px;
	}

	a {
		display: block;
		color: $c-white;
		letter-spacing: 3px;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		width: 100%;
		height: 100%;
	}

	&--icon {
		background: transparent url(../../media/img/download_file.png);
		margin: 0 auto 12px;
		display: block;
		width: 16px;
		height: 19px;
	}
}

.close--button {
	position: absolute;
	width: 21px;
	height: 21px;
	padding: 0;
	background: transparent url(../../media/img/icon-close.png) 0 0 no-repeat;

	@media $mq-tablet {
		padding: 0;
	}
}
