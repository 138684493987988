* {
	box-sizing: border-box;
}

body:after {
	content: 'small';
	display: block;
	width: 0;
	height: 0;
	overflow: hidden;

	@media $mq-tablet {
		content: 'medium';
	}

	@media $mq-desktop {
		content: 'large';
	}
}

.content--wrapper {
	position: relative;
	overflow-x: hidden;
}

.nav--wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
}

main {
	min-height: calc(100vh - 483px);
}

/* min: 23
max: 38 */

section {
	position: relative;
	/* padding: $section-vertical-padding toVw(23px) 0; */
	padding: 0 toVw(23px) 0;
	margin-bottom: $section-vertical-padding;
}

section:last-child {
	margin-bottom: 0;
}

.section--colored--background {
	padding-top: $section-vertical-padding;
}

section h3:first-child {
	/* margin-top: $section-vertical-padding; */
	margin-bottom: $section-vertical-padding;
	padding-top: $section-vertical-padding;
	padding-bottom: $section-vertical-padding;
}

.section--bleed {
	max-width: 2560px;
	margin: auto auto $section-vertical-padding;
	padding: 0;
}

section.page-title {
	padding: calc(11px + 8 * ((100vw - 360px) / 920)) toVw(23px);
	background-color: $c-primary-1;
}

section.page-subtitle,
section.page-subtitle h3 {
	margin-bottom: 0;
}

section.page-subtitle {
	padding-top: 0;
}

section.page-intro {
	padding-bottom: $section-vertical-padding;
	text-align: center;
	margin-left: auto;
	margin-right: auto;

	@media $mq-tablet {
		padding-left: 0;
		padding-right: 0;
		max-width: 80vw;
	}

	@media $mq-desktop {
		max-width: toVw(800, 1366);
	}

	@media $mq-desktop-xxl {
		max-width: toVw(1233, 2560);
	}
}

section.page-intro strong {
	color: $c-primary-1;
}

main.exit {
	opacity: 0 !important;
	transition-duration: 0.5s;
	min-height: 100vh;
}

main.transition * {
	transition-duration: 0s !important;
}

.loader-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1000;
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.5s ease-out;
}

.loading .loader-wrapper {
	opacity: 1;
	pointer-events: initial;
}

.loader,
.loader:after {
	border-radius: 50%;
	width: 10em;
	height: 10em;
}

.loader {
	font-size: 10px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -5em 0 0 -5em;
	border-top: 1.1em solid rgba(202, 184, 174, 0.2);
	border-right: 1.1em solid rgba(202, 184, 174, 0.2);
	border-bottom: 1.1em solid rgba(202, 184, 174, 0.2);
	border-left: 1.1em solid #cab8ae;
	transform: translateZ(0);
	animation: loaderAnimation 1.1s infinite linear;
}

@keyframes loaderAnimation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
