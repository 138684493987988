.brand-logo {
	position: relative;
	width: percentage(106 / 320);
	max-width: 140px;
}

/* .cosc-logo {
	width: 59px;
} */

.brand-logo a,
.cosc-logo a {
	display: block;
	width: 100%;
	height: 0;
	white-space: nowrap;
	overflow: hidden;
	text-indent: 100%;
}

.brand-logo a {
	padding-bottom: percentage(28 / 106);
	background: transparent url(../../media/img/jc-logo.png) 0 0 no-repeat;
	background-size: cover;
}

.cosc-logo a {
	padding-bottom: percentage(55 / 179);
	background: transparent url(../../media/img/cosc-logo2.png) 0 0 no-repeat;
	background-size: cover;
}

footer .brand-logo,
footer .cosc-logo {
	width: 140px;
}
