.collection--highlight {
	position: relative;
	margin: 1rem auto;
	max-width: 980px;
	background-size: cover;
	background-position: center;

	a:first-of-type {
		display: block;
		height: 0;
		padding-bottom: percentage(218 / 358);

		@media screen and (min-width: 1961px) {
			padding-bottom: percentage(402 / 677);
		}
	}

	a:first-of-type > div {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}

	.clock img {
		position: absolute;
		width: toVw(96);
		top: 50%;
		transform: translate(15%, -50%);
		transition: transform 1s cubic-bezier(0.1, 0.6, 0.4, 1);
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;
		padding: 10px;
		height: 100%;
	}

	h2,
	h3 {
		color: $c-white;
		text-align: right;
		max-width: 65%;
	}

	h2 {
		padding-bottom: 0.15em;
		font-size: 33px;
	}

	h3 {
		margin: 0;
		max-width: 12em;
		font-family: 'Lato', sans-serif;
		font-weight: 700;
		font-size: 12px;

		@media $mq-tablet {
			max-width: 65%;
		}
	}

	.button {
		display: none;
	}
}

.collection--highlight:first-child {
	margin-top: 0;
}

@media $mq-tablet {
	.collection--highlight {
		margin-bottom: 0;
	}

	.collection--highlight h2 {
		padding-bottom: 0;
	}

	.collection--highlight .content {
		padding: percentage(40 / 980) percentage(50 / 980);
	}

	.collection--highlights--row {
		display: flex;
		/* max-width: toVw(1114, 1366); */
		margin-left: auto;
		margin-right: auto;
		align-items: center;
	}

	.collection--highlights--row .collection--highlight {
		flex: 1;
		margin-right: 8px;
	}

	.collection--highlights--row .collection--highlight:first-child {
		margin-top: 1rem;
		/* margin-bottom: 1rem; */
	}

	.collection--highlights--row .collection--highlight:last-child {
		margin-right: 0;
	}

	.collection--highlights--row .clock img {
		width: percentage(175 / 552);
	}

	.collection--highlights--column .collection--highlight a:first-of-type {
		padding-bottom: percentage(338 / 980);
	}

	.collection--highlights--column .collection--highlight .clock {
		position: absolute;
		width: percentage(410 / 980);
		height: 100%;
		padding: 0;
		justify-content: center;
		background-color: $c-grey;
		overflow: hidden;
	}

	.collection--highlights--column .collection--highlight img {
		width: 60%;
		left: 50%;
		transform: translate(-50%, -45%);
	}

	.collection--highlights--column .collection--highlight .content {
		padding: percentage(40 / 980) percentage(50 / 980);
		justify-content: flex-start;
	}

	.collection--highlights--column .collection--highlight .button {
		display: initial;
		position: absolute;
		right: percentage(50 / 980);
		bottom: percentage(55 / 338);
		padding: 15px 55px;
	}
}

/* @media screen and (min-width:1024px){
	.collection--highlight h2{
		font-size: 145px;
		background: green;
	}
} */

@media $mq-desktop {
	.collection--highlights--row {
		max-width: toVw(1110, 1366);
	}

	.collection--highlight h2 {
		font-size: calc(43px + 22 * ((100vw - 1024px) / 1536));
	}
}

@media $mq-desktop-xxl {
	.collection--highlights--row {
		max-width: toVw(1365, 2560);
	}
}

@media screen and (min-width: 1961px) {
	.collection--highlights--column .collection--highlight .button {
		padding: 20px 75px;
	}
}

.no-touch .collection--highlight:hover .button:after {
	transform: scaleX(1);
}

.no-touch .collection--highlight:hover .clock img {
	transform: translate(15%, -50%) scale(1.025);
}

.no-touch .collection--highlights--column .collection--highlight:hover img {
	@media $mq-tablet {
		transform: translate(-50%, -45%) scale(1.025);
	}
}
