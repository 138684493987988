/*!
 * @name           common.css
 * @build          Mon, Dec 2, 2019 11:02 PM ET
 */

/* BREAKPOINTS */

/* COLORS */

/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
	display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */

*[hidden] {
    display: none;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

/*  https://www.npmjs.com/package/reset-css  */

/*
  si se quiere pasar null a line, no pasar valor
  ej: @add-mixin fontResponsive 12px, 22px;
*/

body {
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	font-size: 20px;
}

h1 {
	color: #ffffff;
	font-family: 'Playfair Display', serif;
	font-weight: 700;
	font-size: calc(30px + 32 * ((100vw - 360px) / 1640));
		line-height: 1;
	text-align: center;
	margin: 0 auto;
}

h2 {
	color: #cab8ae;
	font-family: 'Playfair Display', serif;
	font-weight: 700;
	font-size: calc(34px + 0 * ((100vw - 360px) / 1640));
		line-height: 1.3;
}

h3 {
	color: #1d1d1d;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	margin: 0 auto;
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: calc(11px + 5 * ((100vw - 1366px) / 554));
	line-height: 1.25;
}

h4 {
	color: #cab8ae;
	border-bottom: 1px solid #cab8ae;
	padding-bottom: 14px;
	margin: 20px 0;
	text-transform: uppercase;
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: calc(18px + 0 * ((100vw - 360px) / 1640));
		line-height: 1.5;
}

p {
	color: #1d1d1d;
	margin-bottom: 8px;
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	font-size: 15px;
	line-height: 1.4;
}

p.textTitle, p strong {
		font-family: 'Lato', sans-serif;
		font-weight: 700;
	}

.page-intro p, .page-intro strong {
	font-family: 'Playfair Display', serif;
	font-weight: 700;
	font-size: calc(20px + 6 * ((100vw - 360px) / 664));
}

.boldText {
	font-family: 'Lato', sans-serif;
	font-weight: 700;
}

.textHighlighted {
	margin: 20px auto 80px;
	text-align: center;
	width: 92%;
	max-width: 790px;
	word-break: break-word;
	font-family: 'Playfair Display', serif;
	font-weight: 700;
	font-size: calc(22px + 2 * ((100vw - 360px) / 1640));
		line-height: 1.4;
}

.textHighlighted p {
		font-family: 'Playfair Display', serif;
		font-weight: 700;
		font-size: calc(22px + 6 * ((100vw - 360px) / 1640));
		line-height: 1.4;
	}

.textHighlighted p strong {
			color: #cab8ae;
		}

.titleSection {
	text-transform: uppercase;
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: calc(13px + 13 * ((100vw - 360px) / 1640));
		line-height: 1.5;
}

.blackColor {
	color: #1d1d1d;
}

.colorWhite {
	color: #ffffff;
}

.primaryColor {
	color: #cab8ae;
}

a {
	text-decoration: none;
}

* {
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
}

body:after {
	content: 'small';
	display: block;
	width: 0;
	height: 0;
	overflow: hidden;
}

.content--wrapper {
	position: relative;
	overflow-x: hidden;
}

.nav--wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
}

main {
	min-height: calc(100vh - 483px);
}

/* min: 23
max: 38 */

section {
	position: relative;
	/* padding: $section-vertical-padding toVw(23px) 0; */
	padding: 0 6.388888888888889vw 0;
	margin-bottom: calc(21px + 8*(100vw - 360px)/920);
}

section:last-child {
	margin-bottom: 0;
}

.section--colored--background {
	padding-top: calc(21px + 8*(100vw - 360px)/920);
}

section h3:first-child {
	/* margin-top: $section-vertical-padding; */
	margin-bottom: calc(21px + 8*(100vw - 360px)/920);
	padding-top: calc(21px + 8*(100vw - 360px)/920);
	padding-bottom: calc(21px + 8*(100vw - 360px)/920);
}

.section--bleed {
	max-width: 2560px;
	margin: auto auto calc(21px + 8*(100vw - 360px)/920);
	padding: 0;
}

section.page-title {
	padding: calc(11px + 8*(100vw - 360px)/920) 6.388888888888889vw;
	background-color: #cab8ae;
}

section.page-subtitle, section.page-subtitle h3 {
	margin-bottom: 0;
}

section.page-subtitle {
	padding-top: 0;
}

section.page-intro {
	padding-bottom: calc(21px + 8*(100vw - 360px)/920);
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

section.page-intro strong {
	color: #cab8ae;
}

main.exit {
	opacity: 0 !important;
	-webkit-transition-duration: 0.5s;
	        transition-duration: 0.5s;
	min-height: 100vh;
}

main.transition * {
	-webkit-transition-duration: 0s !important;
	        transition-duration: 0s !important;
}

.loader-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1000;
	pointer-events: none;
	opacity: 0;
	-webkit-transition: opacity 0.5s ease-out;
	transition: opacity 0.5s ease-out;
}

.loading .loader-wrapper {
	opacity: 1;
	pointer-events: initial;
}

.loader, .loader:after {
	border-radius: 50%;
	width: 10em;
	height: 10em;
}

.loader {
	font-size: 10px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -5em 0 0 -5em;
	border-top: 1.1em solid rgba(202, 184, 174, 0.2);
	border-right: 1.1em solid rgba(202, 184, 174, 0.2);
	border-bottom: 1.1em solid rgba(202, 184, 174, 0.2);
	border-left: 1.1em solid #cab8ae;
	-webkit-transform: translateZ(0);
	        transform: translateZ(0);
	-webkit-animation: loaderAnimation 1.1s infinite linear;
	        animation: loaderAnimation 1.1s infinite linear;
}

@-webkit-keyframes loaderAnimation {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes loaderAnimation {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.button, button {
	position: relative;
	cursor: pointer;
	border: none;
	background-color: #cab8ae;
	color: #ffffff;
	display: inline-block;
	letter-spacing: 0.2em;
	line-height: 1.5;
	padding: 5px;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
	outline: none;
	z-index: 1;

	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: 11px;
}

.button:hover, button:hover {
		/*background-color: lighten($c-primary-1, 5%);*/
	}

.button.blackButton, button.blackButton {
		background-color: #1d1d1d;
		color: #ffffff;
	}

.button.blackButton:hover, button.blackButton:hover {
			/*background-color: lighten($c-black-light, 5%);*/
		}

.no-touch .button:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #9d8d85;
	z-index: -1;

	-webkit-transform-origin: 0 50%;

	        transform-origin: 0 50%;
	-webkit-transform: scaleX(0);
	        transform: scaleX(0);
	-webkit-transition: -webkit-transform 0.25s ease-out;
	transition: -webkit-transform 0.25s ease-out;
	transition: transform 0.25s ease-out;
	transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.no-touch .button:hover:after {
	-webkit-transform: scaleX(1);
	        transform: scaleX(1);
}

.downloadButton {
	cursor: pointer;
	background-color: #cab8ae;
	padding: 18px 0;
	width: 100%;

	font-family: 'Lato', sans-serif;
	font-weight: 700;
	font-size: calc(13px + 0 * ((100vw - 360px) / 1640));
		line-height: 1.5;
}

.downloadButton a {
		display: block;
		color: #ffffff;
		letter-spacing: 3px;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		width: 100%;
		height: 100%;
	}

.downloadButton--icon {
		background: transparent url(/static/media/img/download_file.png);
		margin: 0 auto 12px;
		display: block;
		width: 16px;
		height: 19px;
	}

.close--button {
	position: absolute;
	width: 21px;
	height: 21px;
	padding: 0;
	background: transparent url(/static/media/img/icon-close.png) 0 0 no-repeat;
}

img, video {
	display: block;
	height: auto;
	max-width: 100%;
}

.brand-logo {
	position: relative;
	width: 33.125%;
	max-width: 140px;
}

/* .cosc-logo {
	width: 59px;
} */

.brand-logo a, .cosc-logo a {
	display: block;
	width: 100%;
	height: 0;
	white-space: nowrap;
	overflow: hidden;
	text-indent: 100%;
}

.brand-logo a {
	padding-bottom: 26.415094339%;
	background: transparent url(/static/media/img/jc-logo.png) 0 0 no-repeat;
	background-size: cover;
}

.cosc-logo a {
	padding-bottom: 30.726256983%;
	background: transparent url(/static/media/img/cosc-logo2.png) 0 0 no-repeat;
	background-size: cover;
}

footer .brand-logo, footer .cosc-logo {
	width: 140px;
}

header {
	position: fixed;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	width: 100vw;
	padding: 17px 35px;
	z-index: 110;
	background-color: #302f2f;
	-webkit-transition: all 0.25s ease-out;
	transition: all 0.25s ease-out;
}

.header--transparent header {
	background-color: transparent;
}

header.opaque, .header--transparent.nav-visible header {
	background-color: #302f2f;
}

.touch .header--transparent header.menu--lang-visible {
	background-color: #302f2f;
}

header > div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	max-width: 2490px;
	margin: auto;
}

header .menu--wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
}

header .menu--wrapper:last-child {
	padding-left: 20px;
}

.menu--lang {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	position: absolute;
	top: -0.4em;
	left: -1.25em;
	height: 1.15em;
	min-height: 2em;
	cursor: pointer;
}

header.menu--lang-visible .menu--lang {
	height: auto;
	z-index: 10;
}

.menu--lang li {
	opacity: 0;
	-webkit-box-ordinal-group: 3;
	    -ms-flex-order: 2;
	        order: 2;
	text-align: center;
	-webkit-transition: all 0.25s ease-out;
	transition: all 0.25s ease-out;
}

.menu--lang li{
	pointer-events: none;
}

.no-touch .menu--lang li:not(.selected), .no-touch .menu--lang:hover li.selected {
	background: #302f2f;
}

.menu--lang li.selected, .no-touch .menu--lang:hover li, header.menu--lang-visible .menu--lang li {
	opacity: 1;
	pointer-events: all;
}

/*
.no-touch header.menu--lang-visible .menu--lang li{
	@media $mq-tablet{
		background: $c-black;
	}
} */

.menu--lang li.selected {
	-webkit-box-ordinal-group: 2;
	    -ms-flex-order: 1;
	        order: 1;
	pointer-events: none;
}

.menu--lang li a {
	display: block;
	padding: 10px 10px 9px;
	font-family: 'Lato', sans-serif;
	font-weight: 300;
}

.no-touch .menu--lang li:not(.selected):hover {
	background: #cab8ae;
}

header li a {
	color: #ffffff;
	text-decoration: none;
	font-size: calc(12px + 2 * ((100vw - 360px) / 1640));
		line-height: 1;
}

header [data-is-menu-trigger] {
	position: relative;
	width: 28px;
	height: 14px;
	margin-left: 10px;
	border-top: 2px solid #fff;
	cursor: pointer;
}

header [data-is-menu-trigger]:before, header [data-is-menu-trigger]:after {
	content: '';
	position: absolute;
	left: 0;
	width: 100%;
	height: 2px;
	background: #fff;
}

header [data-is-menu-trigger]:before {
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
}

header [data-is-menu-trigger]:after {
	top: 100%;
}

header [data-is-menu-trigger], header [data-is-menu-trigger]:before, header [data-is-menu-trigger]:after {
	-webkit-transition: all 0.25s ease-out;
	transition: all 0.25s ease-out;
}

.no-touch header [data-is-menu-trigger]:hover {
	border-color: #cab8ae;
}

.no-touch header [data-is-menu-trigger]:hover:before, .no-touch header [data-is-menu-trigger]:hover:after {
	background-color: #cab8ae;
}

.nav-visible header [data-is-menu-trigger] {
	-webkit-transform: rotateZ(-45deg) translateX(0px) translateY(7px);
	        transform: rotateZ(-45deg) translateX(0px) translateY(7px);
}

.nav-visible header [data-is-menu-trigger]:before {
	opacity: 0;
}

.nav-visible header [data-is-menu-trigger]:after {
	-webkit-transform: rotate(90deg) translateX(-14px) translateY(1px);
	        transform: rotate(90deg) translateX(-14px) translateY(1px);
}

/* Esta cabecera duplicada nos sirve para que el contenido fluya a pesar de tener la otra cabecera con position:fixed */

.adjustment-header header {
	position: relative;
	visibility: hidden;
}

.header--transparent .adjustment-header {
	display: none;
}

.header--transparent.nav-visible .nav--wrapper .adjustment-header {
	display: initial;
}

footer {
	position: relative;
	padding: 2em 6.388888888888889vw;
	font-size: 12px;
	max-width: 1140px;
	margin: auto;
	margin-top: calc(110px + 50*(100vw - 360px)/1006);
	z-index: 10;
}

footer > div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	max-width: 1120px;
	margin: auto;
}

footer:after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: calc(50% - 50vw);
	right: calc(50% - 50vw);
	background-color: #302f2f;
	z-index: -1;
}

.footer--menu {
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	width: 50%;
}

.footer--menu:nth-child(2) {
	padding-left: 10px;
}

.footer--menu a {
	display: inline-block;
	margin-top: 1em;
	color: #aaaaaa;
	letter-spacing: 2px;
	text-decoration: none;
	text-transform: uppercase;
	line-height: 1.25;
	-webkit-transition: all 0.25s ease-out;
	transition: all 0.25s ease-out;
}

.footer--menu a.titleMenu {
		color: #aaaaaa;
		font-weight: bold;
	}

.footer--menu a:hover, .footer--menu a.titleMenu:hover {
		color: #ffffff;
	}

.footer--menu:nth-child(2) a {
	color: #606060;
}

.footer--menu:nth-child(2) a:hover {
	color: #ffffff;
}

.footer--menu:nth-child(2) li:first-child a {
	color: #aaaaaa;
}

.footer--menu:nth-child(2) li:first-child a:hover {
	color: #ffffff;
}

.footer--menu--secondPages {
	width: 100%;
	margin-top: 2.5em;
	margin-bottom: 2.5em;
}

.footer--menu--secondPages a {
	width: 100%;
	padding: 0 0 0.75em 1em;
	color: #606060;
	border-bottom: 1px solid #606060;
}

footer div div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	width: 100%;
	margin-top: 1em;
}

.main-navigation-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #302f2f;
	opacity: 0;
	pointer-events: none;
	z-index: 100;
	-webkit-transition: opacity 0.25s ease-out;
	transition: opacity 0.25s ease-out;
}

.main-navigation {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	padding-top: 17.5%;
	-webkit-transform: translateX(100vw);
	        transform: translateX(100vw);
	-webkit-transition: all 0.25s ease-out;
	transition: all 0.25s ease-out;
	z-index: 100;
	background-color: #302f2f;
	overflow-y: scroll;
}

.main-navigation li {
		letter-spacing: 3px;
		text-transform: uppercase;

		font-family: 'Lato', sans-serif;
		font-weight: 700;
		font-size: calc(13px + 0 * ((100vw - 360px) / 1640));
		line-height: 1.5;
	}

.main-navigation a {
		display: block;
		position: relative;
		color: #ffffff;
		padding: 20px 50px;
		z-index: 1;
	}

.main-navigation a:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #cab8ae;
		z-index: -1;

		-webkit-transform-origin: 0 50%;

		        transform-origin: 0 50%;
		-webkit-transform: scaleX(0);
		        transform: scaleX(0);
		-webkit-transition: -webkit-transform 0.25s ease-out;
		transition: -webkit-transform 0.25s ease-out;
		transition: transform 0.25s ease-out;
		transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
	}

.main-navigation a.active {
		background-color: #cab8ae;
	}

.main-navigation li li a {
		padding-left: 80px;
		color: #606060;
		background: #3a3b3c;
		/* text-decoration: none; */
	}

.no-touch .main-navigation a:hover:after {
	-webkit-transform: scaleX(1);
	        transform: scaleX(1);
}

.nav-visible .main-navigation-overlay {
	opacity: 1;
	pointer-events: all;
}

.nav-visible .main-navigation {
	-webkit-transform: none;
	        transform: none;
}

main, footer {
	-webkit-transition: all 0.25s ease-out;
	transition: all 0.25s ease-out;
}

.nav-visible main, .nav-visible footer {
	-webkit-transform: translateX(-100vw);
	        transform: translateX(-100vw);
}

.cookies--warning--wrapper {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	background: #d7d7d7;
	/* border-top: 2px solid $c-primary-1; */
	padding: 1em 6.388888888888889vw;
	z-index: 100;
	-webkit-transform: translateY(110%);
	        transform: translateY(110%);
	opacity: 0;
	-webkit-transition: all 0.25s ease-out;
	transition: all 0.25s ease-out;
}

.cookies--warning--wrapper.visible {
	-webkit-transform: none;
	        transform: none;
	opacity: 1;
}

.cookies--warning--wrapper h2, .cookies--warning--wrapper p {
	color: #302f2f;
	font-size: 15px;
}

.cookies--warning--wrapper h2 {
	margin-bottom: 1em;
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	text-transform: uppercase;
}

.cookies--warning--wrapper p {
	margin-bottom: 0;
}

.cookies--warning--wrapper .button {
	float: right;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	width: auto;
	padding: 10px;
	margin: auto;
}

.cookies--warning--wrapper .close--button {
	top: 10px;
	right: 11.11111111111111vw;
}

.collection--highlight {
	position: relative;
	margin: 1rem auto;
	max-width: 980px;
	background-size: cover;
	background-position: center;
}

.collection--highlight a:first-of-type {
		display: block;
		height: 0;
		padding-bottom: 60.893854748%;
	}

.collection--highlight a:first-of-type > div {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}

.collection--highlight .clock img {
		position: absolute;
		width: 26.666666666666668vw;
		top: 50%;
		-webkit-transform: translate(15%, -50%);
		        transform: translate(15%, -50%);
		-webkit-transition: -webkit-transform 1s cubic-bezier(0.1, 0.6, 0.4, 1);
		transition: -webkit-transform 1s cubic-bezier(0.1, 0.6, 0.4, 1);
		transition: transform 1s cubic-bezier(0.1, 0.6, 0.4, 1);
		transition: transform 1s cubic-bezier(0.1, 0.6, 0.4, 1), -webkit-transform 1s cubic-bezier(0.1, 0.6, 0.4, 1);
	}

.collection--highlight .content {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		-webkit-box-align: end;
		    -ms-flex-align: end;
		        align-items: flex-end;
		-webkit-box-pack: end;
		    -ms-flex-pack: end;
		        justify-content: flex-end;
		padding: 10px;
		height: 100%;
	}

.collection--highlight h2, .collection--highlight h3 {
		color: #ffffff;
		text-align: right;
		max-width: 65%;
	}

.collection--highlight h2 {
		padding-bottom: 0.15em;
		font-size: 33px;
	}

.collection--highlight h3 {
		margin: 0;
		max-width: 12em;
		font-family: 'Lato', sans-serif;
		font-weight: 700;
		font-size: 12px;
	}

.collection--highlight .button {
		display: none;
	}

.collection--highlight:first-child {
	margin-top: 0;
}

/* @media screen and (min-width:1024px){
	.collection--highlight h2{
		font-size: 145px;
		background: green;
	}
} */

.no-touch .collection--highlight:hover .button:after {
	-webkit-transform: scaleX(1);
	        transform: scaleX(1);
}

.no-touch .collection--highlight:hover .clock img {
	-webkit-transform: translate(15%, -50%) scale(1.025);
	        transform: translate(15%, -50%) scale(1.025);
}

figure {
	position: relative;
}

.figure-2-columns-wrapper figure {
	margin: 1em auto;
}

.figure-2-columns-wrapper figure:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
}

.figure-2-columns {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	    -ms-flex-direction: column-reverse;
	        flex-direction: column-reverse;
	padding: calc(21px + (1500vw - 5400px)/920) 0;
}

.figure-2-columns figcaption {
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
	padding-bottom: calc(10px + (1500vw - 5400px)/920);
}

.figure-2-columns img {
	margin: 1em auto;
}

.figure-2-columns video {
	cursor: pointer;
}

.figure-2-columns h2 {
	margin-bottom: 0.5em;
}

.figure-2-columns > div {
	margin: auto;
	overflow: hidden;
	/* height: 0;
	padding-bottom: 100%; */
}

.collection-feature figcaption {
	position: relative;
	z-index: 2;
	/* overflow-y: hidden; */
}

.collection-feature > div {
	position: relative;
	z-index: 3;
}

.collection-feature:nth-child(odd) {
	padding-bottom: 0;
}

.collection-feature:nth-child(odd) figcaption {
	padding-top: calc(21px + (1500vw - 5400px)/920);
}

.collection-feature:nth-child(odd) figcaption:after, .collection-feature.collection-feature-video:after {
	content: '';
	position: absolute;
	top: 0;
	left: calc(50% - 100vw);
	right: calc(50% - 100vw);
	bottom: -3em;
	background-color: #cab8ae;
	z-index: -1;
}

.collection-feature.collection-feature-video > div:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 3em;
	left: 75%;
	width: 33px;
	height: 33px;
	background: transparent url(/static/media/img/icon-play.png) 0 0 no-repeat;
	pointer-events: none;
}

.collection-feature.collection-feature-video.playing > div:after {
	display: none;
}

.collection-feature.collection-feature-video figcaption:after {
	display: none;
}

.collection-feature.collection-feature-video:after {
	bottom: 0;
	background-color: #000;
}

.collection-feature.collection-feature-video figure > div {
	padding: 2rem 0;
}

.collection-feature:nth-child(odd) figcaption h2 {
	color: #ffffff;
}

.collection-feature.collection-feature-video h2, .collection-feature.collection-feature-video p {
	color: #ffffff;
}

/* @media $mq-desktop-xxl {
	.figure-2-columns-wrapper .collection-feature {
		max-width: toVw(1488, 2560);
	}

	.collection-feature > div {
		width: percentage(785 / 1488);
	}
	.collection-feature figcaption {
		width: percentage(703 / 1488);
	}

	.collection-feature img {
		width: calc(447px + 90 * ((100vw - 1920px) / 640));
		max-width: 537px;
	}

	.collection-feature h2 {
		font-size: calc(34px + 8 * ((100vw - 1920px) / 640));
	}

	.collection-feature p {
		font-size: 22px;
	}
} */

@media screen and
	(
		min-width: 768px
	) {

body:after {
		content: 'medium'
}

section.page-intro {
		padding-left: 0;
		padding-right: 0;
		max-width: 80vw
}

.button, button {
		margin: 8px 4px 0 4px;
		padding: 15px 10px
}

.downloadButton {
		padding: 24px 0;
		max-width: 270px
}

.close--button {
		padding: 0
}

.footer--menu {
		width: 35%
}

.footer--menu--secondPages {
		width: 100% !important
}

.footer--brands {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		    -ms-flex-pack: end;
		        justify-content: flex-end;
		width: auto
}

footer .brand-logo {
		margin-right: 3em
}

.main-navigation {
		bottom: auto;
		width: 280px;
		height: 100vh;
		top: auto;
		padding-top: 0;
		-webkit-transform: translateX(280px);
		        transform: translateX(280px)
}

.nav-visible .main-navigation-overlay {
		opacity: .5
}

.nav-visible main, .nav-visible footer {
		-webkit-transform: translateX(-280px);
		        transform: translateX(-280px)
}

.cookies--warning--wrapper {
		background: #f2f2f2;
		border-top: none
}

.cookies--warning--wrapper .close--button {
		top: 1em;
		right: 30px
}

.collection--highlight h3 {
			max-width: 65%
	}
	.collection--highlight {
		margin-bottom: 0;
	}

	.collection--highlight h2 {
		padding-bottom: 0;
	}

	.collection--highlight .content {
		padding: 4.081632653% 5.102040816%;
	}

	.collection--highlights--row {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		/* max-width: toVw(1114, 1366); */
		margin-left: auto;
		margin-right: auto;
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
	}

	.collection--highlights--row .collection--highlight {
		-webkit-box-flex: 1;
		    -ms-flex: 1;
		        flex: 1;
		margin-right: 8px;
	}

	.collection--highlights--row .collection--highlight:first-child {
		margin-top: 1rem;
		/* margin-bottom: 1rem; */
	}

	.collection--highlights--row .collection--highlight:last-child {
		margin-right: 0;
	}

	.collection--highlights--row .clock img {
		width: 31.70289855%;
	}

	.collection--highlights--column .collection--highlight a:first-of-type {
		padding-bottom: 34.489795918%;
	}

	.collection--highlights--column .collection--highlight .clock {
		position: absolute;
		width: 41.836734693%;
		height: 100%;
		padding: 0;
		-webkit-box-pack: center;
		    -ms-flex-pack: center;
		        justify-content: center;
		background-color: #f2f2f2;
		overflow: hidden;
	}

	.collection--highlights--column .collection--highlight img {
		width: 60%;
		left: 50%;
		-webkit-transform: translate(-50%, -45%);
		        transform: translate(-50%, -45%);
	}

	.collection--highlights--column .collection--highlight .content {
		padding: 4.081632653% 5.102040816%;
		-webkit-box-pack: start;
		    -ms-flex-pack: start;
		        justify-content: flex-start;
	}

	.collection--highlights--column .collection--highlight .button {
		display: initial;
		position: absolute;
		right: 5.102040816%;
		bottom: 16.272189349%;
		padding: 15px 55px;
	}

.no-touch .collection--highlights--column .collection--highlight:hover img {
		-webkit-transform: translate(-50%, -45%) scale(1.025);
		        transform: translate(-50%, -45%) scale(1.025)
}

.figure-2-columns img {
		margin: 1em 0
}
	}

@media screen and
	(
		min-width: 1024px
	) {

p {
		font-size: calc(16px + 7*(100vw - 1024px)/1536)
}

body:after {
		content: 'large'
}

section.page-intro {
		max-width: 58.565153733528554vw
}

footer {
		padding: 4.24597364568082vw 6.388888888888889vw
}

footer > div {
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between
}

.footer--menu {
		padding: 0 12px;
		min-width: 15%;
		width: 20%
}

.footer--menu--secondPages {
		width: 40% !important;
		margin-top: 0;
		margin-bottom: 0;
		padding-right: 2em
}

footer div div {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		    -ms-flex-direction: column;
		        flex-direction: column;
		width: 25%
}

.footer--brands {
		-webkit-box-pack: start;
		    -ms-flex-pack: start;
		        justify-content: flex-start
}

footer .cosc-logo {
		-webkit-box-ordinal-group: 2;
		    -ms-flex-order: 1;
		        order: 1;
		margin-top: 2em
}

footer .cosc-logo {
		margin-top: 0
}

footer .brand-logo {
		-webkit-box-ordinal-group: 3;
		    -ms-flex-order: 2;
		        order: 2;
		margin: 3em 0 0 0
}

.cookies--warning--wrapper {
		padding: 3.125vw 30px 2.0833333333333335vw
}

.cookies--warning--wrapper h2, .cookies--warning--wrapper p {
		max-width: 52.083333333333336vw
}

.cookies--warning--wrapper .button {
		position: absolute;
		float: none;
		bottom: 2.0833333333333335vw;
		left: 55.55555555555556vw;
		padding: 15px 50px
}
	.collection--highlights--row {
		max-width: 81.25915080527086vw;
	}

	.collection--highlight h2 {
		font-size: calc(43px + 22*(100vw - 1024px)/1536);
	}

.figure-2-columns-wrapper figure {
		max-width: 74.6705710102489vw
}
	}

@media screen and (min-width: 1024px) {

	.page-intro p, .page-intro strong {
		font-size: 26px;
	}
	.figure-2-columns {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		    -ms-flex-direction: row;
		        flex-direction: row;
		-webkit-box-align: start;
		    -ms-flex-align: start;
		        align-items: flex-start;
		-webkit-box-pack: justify;
		    -ms-flex-pack: justify;
		        justify-content: space-between;
		width: calc(1020px + 466*(100vw - 1366px)/1194);
		max-width: 1484px;
	}

	.figure-2-columns > div {
		width: 56.470588235%;
		/* width: 42%; */
		max-width: none;
	}

	.figure-2-columns figcaption {
		width: 43.529411764%;
		/* width: 58%; */
		max-width: none;
	}

	.figure-2-columns:nth-of-type(odd) img {
		margin-left: 0;
	}

	.figure-2-columns:nth-of-type(even) img {
		margin-right: 0;
		margin-left: auto;
	}

	.figure-2-columns img {
		/* width: calc(357px + 180 * ((100vw - 1024px) / 1536)); */
		/* width: calc(357px + 180 * ((100vw - 1366px) / 1194)); */
		width: calc(437px + 180*(100vw - 1366px)/1194);
		max-width: 537px;
		margin: 0;
	}

	.figure-2-columns p {
		margin-bottom: 16px;
	}

	.figure-2-columns-wrapper figure:nth-of-type(even) {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		    -ms-flex-direction: row-reverse;
		        flex-direction: row-reverse;
	}
	.figure-2-columns-wrapper .collection-feature {
		padding: 0 0 2em;
		margin-bottom: 2em;
	}

	.figure-2-columns-wrapper .collection-feature:nth-child(odd) {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		    -ms-flex-direction: row-reverse;
		        flex-direction: row-reverse;
	}

	.figure-2-columns-wrapper .collection-feature:nth-child(even) {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		    -ms-flex-direction: row;
		        flex-direction: row;
	}

	.collection-feature.collection-feature-video:after {
		background: #000;
	}

	.collection-feature:nth-child(odd) figcaption:after {
		display: none;
	}

	/* .collection-feature > div {
		width: percentage(576 / 1020);
		max-width: none;
	}

	.collection-feature figcaption {
		width: percentage(444 / 1020);
		max-width: none;
	} */

	.collection-feature figcaption, .collection-feature:nth-child(odd) figcaption {
		padding: 4em 0 0 0;
	}

	.collection-feature:nth-child(2) figcaption {
		padding-top: 0;
		margin-top: 0;
	}

	.collection-feature:before, .collection-feature:after {
		content: '';
		position: absolute;
		left: calc(50% - 100vw);
		right: calc(50% - 100vw);
	}

	.collection-feature:after {
		top: -2px;
		bottom: -2em;
		background-color: #cab8ae;
		z-index: 1;
	}

	.collection-feature:before {
		background-color: #ffffff;
		z-index: 2;
	}

	.collection-feature:nth-child(even):before {
		top: 2em;
		bottom: -2px;
	}

	.collection-feature:nth-child(2):before {
		top: -4px;
		bottom: -2px;
	}

	.collection-feature:nth-child(odd):before {
		top: -4px;
		height: 2em;
	}

	.collection-feature:nth-child(even):after {
		top: -2px;
		bottom: 0;
	}

	.collection-feature:nth-of-type(even) img {
		margin-left: 0;
		margin-right: auto;
	}

	.collection-feature:nth-of-type(odd) img {
		margin-left: auto;
		margin-right: 0;
	}

	.collection-feature.collection-feature-video {
		-webkit-box-align: center;
		    -ms-flex-align: center;
		        align-items: center;
		padding: calc(41px + (1500vw - 5400px)/920) 0;
		/* margin-bottom: calc(41px + (1500vw - 5400px) / 920); */
		margin-bottom: 5em;
	}

	.collection-feature.collection-feature-video > div:after {
		top: 75%;
		left: 85%;
	}
}

@media screen and
	(
		min-width: 1366px
	) {

footer {
		padding: 4.24597364568082vw 0
}
	.collection-feature img {
		margin: 0;
	}

	.collection-feature figcaption {
		margin-top: 2em;
	}

	.collection-feature:nth-child(even):before {
		bottom: 5em;
	}

	.collection-feature:nth-child(even):after {
		top: auto;
		bottom: -3em;
		height: 8em;
	}

	.collection-feature:nth-child(odd):before {
		height: 0;
	}

	.collection-feature:nth-child(odd):after {
		bottom: 5em;
	}

	.collection-feature.collection-feature-video:after {
		bottom: 0;
	}

	.collection-feature:last-child:after {
		display: none;
	}

	.collection-feature figcaption, .collection-feature:nth-child(odd) figcaption {
		padding: 0;
	}
	}

@media screen and (min-width: 1920px) {

	h3 {
		font-size: 16px;
	}
}

@media screen and
	(
		min-width: 1920px
	) {

section.page-intro {
		max-width: 48.1640625vw
}
	.collection--highlights--row {
		max-width: 53.3203125vw;
	}
	.figure-2-columns h2 {
		font-size: calc(34px + 8*(100vw - 1920px)/640);
	}
	}

@media screen and (min-width: 1961px) {

p {
		line-height: 1.6
}

.page-intro p, .page-intro strong {
		font-size: 32px
}

.collection--highlight a:first-of-type {
			padding-bottom: 59.379615952%
	}
	.collection--highlights--column .collection--highlight .button {
		padding: 20px 75px;
	}
	}

@media screen and (min-width: 2000px) {

	h1 {
		font-size: 62px;
	}

	h2 {
		font-size: 34px;
	}

	h4 {
		font-size: 18px;
	}

	.textHighlighted {
		font-size: 24px;
	}

	.textHighlighted p {
		font-size: 28px;
	}

	.titleSection {
		font-size: 26px;
	}

	.downloadButton {
		font-size: 13px;
	}

	header li a {
		font-size: 14px;
	}

	.main-navigation li {
		font-size: 13px;
	}
}

@media screen and (max-width: 360px) {

	h1 {
		font-size: 30px;
	}

	h2 {
		font-size: 34px;
	}

	h4 {
		font-size: 18px;
	}

	.page-intro p, .page-intro strong {
		font-size: 20px;
	}

	.textHighlighted {
		font-size: 22px;
	}

	.textHighlighted p {
		font-size: 22px;
	}

	.titleSection {
		font-size: 13px;
	}

	.downloadButton {
		font-size: 13px;
	}

	header li a {
		font-size: 12px;
	}

	.main-navigation li {
		font-size: 13px;
	}
}

@media screen and (max-width: 1366px) {

	h3 {
		font-size: 11px;
	}
}

