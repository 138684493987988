.main-navigation-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: $c-black;
	opacity: 0;
	pointer-events: none;
	z-index: 100;
	transition: opacity 0.25s ease-out;
}

.main-navigation {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	padding-top: percentage(56/320);
	transform: translateX(100vw);
	transition: all 0.25s ease-out;
	z-index: 100;
	background-color: $c-black;
	overflow-y: scroll;

	@media $mq-tablet {
		bottom: auto;
		width: 280px;
		height: 100vh;
		top: auto;
		padding-top: 0;
		transform: translateX(280px);
	}

	li {
		letter-spacing: 3px;
		text-transform: uppercase;

		font-family: 'Lato', sans-serif;
		font-weight: 700;
		@add-mixin fontResponsive 13px, 13px;
	}

	a {
		display: block;
		position: relative;
		color: $c-white;
		padding: 20px 50px;
		z-index: 1;
	}

	a:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $c-primary-1;
		z-index: -1;

		transform-origin: 0 50%;
		transform: scaleX(0);
		transition: transform 0.25s ease-out;
	}

	a.active {
		background-color: $c-primary-1;
	}

	li li a {
		padding-left: 80px;
		color: $c-grey-softDark;
		background: #3a3b3c;
		/* text-decoration: none; */
	}
}

.no-touch .main-navigation a:hover:after {
	transform: scaleX(1);
}

.nav-visible .main-navigation-overlay {
	opacity: 1;
	pointer-events: all;

	@media $mq-tablet {
		opacity: .5;
	}
}

.nav-visible .main-navigation {
	transform: none;
}

main,
footer {
	transition: all 0.25s ease-out;
}

.nav-visible main,
.nav-visible footer {
	transform: translateX(-100vw);

	@media $mq-tablet {
		transform: translateX(-280px);
	}
}
