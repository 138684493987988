.cookies--warning--wrapper {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	background: $c-grey-medium;
	/* border-top: 2px solid $c-primary-1; */
	padding: 1em toVw(23px);
	z-index: 100;
	transform: translateY(110%);
	opacity: 0;
	transition: all 0.25s ease-out;

	@media $mq-tablet {
		background: $c-grey;
		border-top: none;
	}

	@media $mq-desktop {
		padding: toVw(45, 1440) 30px toVw(30, 1440);
	}
}

.cookies--warning--wrapper.visible {
	transform: none;
	opacity: 1;
}

.cookies--warning--wrapper h2,
.cookies--warning--wrapper p {
	color: $c-black;
	font-size: 15px;

	@media $mq-desktop {
		max-width: toVw(750, 1440);
	}
}

.cookies--warning--wrapper h2 {
	margin-bottom: 1em;
	font-family: 'Lato', sans-serif;
	font-weight: 700;
	text-transform: uppercase;
}

.cookies--warning--wrapper p {
	margin-bottom: 0;
}

.cookies--warning--wrapper .button {
	float: right;
	box-sizing: border-box;
	width: auto;
	padding: 10px;
	margin: auto;

	@media $mq-desktop {
		position: absolute;
		float: none;
		bottom: toVw(30, 1440);
		left: toVw(800, 1440);
		padding: 15px 50px;
	}
}

.cookies--warning--wrapper .close--button {
	top: 10px;
	right: toVw(40px);

	@media $mq-tablet {
		top: 1em;
		right: 30px;
	}
}
