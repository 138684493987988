@define-mixin fontFamily $type, $weight {
	font-family: $type, sans-serif;
	font-weight: $weight;
}

/*
  si se quiere pasar null a line, no pasar valor
  ej: @add-mixin fontResponsive 12px, 22px;
*/
@define-mixin fontResponsive $sixeMin, $sizeMax, $line {
	font-size: responsive $sixeMin $sizeMax;
	font-range: $w-mobile $w-maxDesktop;

	@if $line {
		line-height: $line;
	} @else {
		line-height: 1.5;
	}
}
