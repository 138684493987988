footer {
	position: relative;
	padding: 2em toVw(23px);
	font-size: 12px;
	max-width: 1140px;
	margin: auto;
	margin-top: calc(110px + 50 * ((100vw - 360px) / 1006));
	z-index: 10;

	@media $mq-desktop {
		padding: toVw(58, 1366) toVw(23px);
	}

	@media $mq-desktop-xl {
		padding: toVw(58, 1366) 0;
	}
}

footer > div {
	display: flex;
	flex-wrap: wrap;
	max-width: 1120px;
	margin: auto;
	@media $mq-desktop {
		justify-content: space-between;
	}
}

footer:after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: calc(50% - 50vw);
	right: calc(50% - 50vw);
	background-color: $c-black;
	z-index: -1;
}

.footer--menu {
	box-sizing: border-box;
	width: 50%;

	@media $mq-tablet {
		width: 35%;
	}
	@media $mq-desktop {
		padding: 0 12px;
		min-width: 15%;
		width: 20%;
	}
}

.footer--menu:nth-child(2) {
	padding-left: 10px;
}

.footer--menu a {
	display: inline-block;
	margin-top: 1em;
	color: $c-grey-light;
	letter-spacing: 2px;
	text-decoration: none;
	text-transform: uppercase;
	line-height: 1.25;
	transition: all 0.25s ease-out;

	&.titleMenu {
		color: $c-grey-light;
		font-weight: bold;
	}

	&:hover,
	&.titleMenu:hover {
		color: $c-white;
	}
}

.footer--menu:nth-child(2) a {
	color: $c-grey-softDark;
}

.footer--menu:nth-child(2) a:hover {
	color: $c-white;
}

.footer--menu:nth-child(2) li:first-child a {
	color: $c-grey-light;
}

.footer--menu:nth-child(2) li:first-child a:hover {
	color: $c-white;
}

.footer--menu--secondPages {
	width: 100%;
	margin-top: 2.5em;
	margin-bottom: 2.5em;

	@media $mq-tablet {
		width: 100% !important;
	}

	@media $mq-desktop {
		width: 40% !important;
		margin-top: 0;
		margin-bottom: 0;
		padding-right: 2em;
	}
}

.footer--menu--secondPages a {
	width: 100%;
	padding: 0 0 0.75em 1em;
	color: $c-grey-softDark;
	border-bottom: 1px solid $c-grey-softDark;
}

footer div div {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 1em;

	@media $mq-desktop {
		flex-direction: column;
		width: 25%;
	}
}

.footer--brands {
	@media $mq-tablet {
		display: flex;
		justify-content: flex-end;
		width: auto;
	}

	@media $mq-desktop {
		justify-content: flex-start;
	}
}

footer .cosc-logo {
	@media $mq-desktop {
		order: 1;
		margin-top: 2em;
	}

	@media $mq-desktop {
		margin-top: 0;
	}
}

footer .brand-logo {
	@media $mq-tablet {
		margin-right: 3em;
	}

	@media $mq-desktop {
		order: 2;
		margin: 3em 0 0 0;
	}
}
