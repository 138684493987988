figure {
	position: relative;
}

.figure-2-columns-wrapper figure {
	margin: 1em auto;

	@media $mq-desktop {
		max-width: toVw(1020, 1366);
	}
}

.figure-2-columns-wrapper figure:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
}

.figure-2-columns {
	position: relative;
	display: flex;
	flex-direction: column-reverse;
	padding: calc(21px + 15 * (100vw - 360px) / 920) 0;
}

.figure-2-columns figcaption {
	flex: 1;
	padding-bottom: calc(10px + 15 * (100vw - 360px) / 920);
}

.figure-2-columns img {
	margin: 1em auto;

	@media $mq-tablet {
		margin: 1em 0;
	}
}

.figure-2-columns video {
	cursor: pointer;
}

.figure-2-columns h2 {
	margin-bottom: 0.5em;
}

.figure-2-columns > div {
	margin: auto;
	overflow: hidden;
	/* height: 0;
	padding-bottom: 100%; */
}

@media screen and (min-width: 1024px) {
	.figure-2-columns {
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		width: calc(1020px + 466 * ((100vw - 1366px) / 1194));
		max-width: 1484px;
	}

	.figure-2-columns > div {
		width: percentage(576 / 1020);
		/* width: 42%; */
		max-width: none;
	}

	.figure-2-columns figcaption {
		width: percentage(444 / 1020);
		/* width: 58%; */
		max-width: none;
	}

	.figure-2-columns:nth-of-type(odd) img {
		margin-left: 0;
	}

	.figure-2-columns:nth-of-type(even) img {
		margin-right: 0;
		margin-left: auto;
	}

	.figure-2-columns img {
		/* width: calc(357px + 180 * ((100vw - 1024px) / 1536)); */
		/* width: calc(357px + 180 * ((100vw - 1366px) / 1194)); */
		width: calc(437px + 180 * ((100vw - 1366px) / 1194));
		max-width: 537px;
		margin: 0;
	}

	.figure-2-columns p {
		margin-bottom: 16px;
	}

	.figure-2-columns-wrapper figure:nth-of-type(even) {
		flex-direction: row-reverse;
	}
}

@media $mq-desktop-xxl {
	.figure-2-columns h2 {
		font-size: calc(34px + 8 * ((100vw - 1920px) / 640));
	}
}
